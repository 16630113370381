import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Container, Typography, Tabs, Tab } from '@material-ui/core';
import config from '../config';
import LogoLeftImage from './shaktibrother.png';

const Live = () => {
    const [liveStream, setLiveStream] = useState(null);
    const [activeTab, setActiveTab] = useState(3);

    useEffect(() => {
        const fetchLiveStream = async () => {
            try {
                const response = await axios.get(`${config.API_BASE_URL}/api/getLiveLink.php`);
                console.log(response.data.live);
                setLiveStream(response.data.live ?? null);
            } catch (error) {
                console.error('Error fetching live stream:', error);
            }
        };

        fetchLiveStream();
    }, []);

    const handleChangeTab = (event, newValue) => {
        setActiveTab(newValue);
    };

    if (!liveStream) {
        return <Typography variant="h2">Loading...</Typography>;
    }

    return (
        <Container maxWidth="md">
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <img src={LogoLeftImage} alt="Left Logo" style={{ width: '100px', height: 'auto', margin: '10px' }} />
            </div>

            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                <Tabs value={activeTab} onChange={handleChangeTab}>
                    <Tab label="Matches" component={Link} to="/matches" />
                    <Tab label="Teams" component={Link} to="/teams" />
                    <Tab label="Awards" component={Link} to="/awards" />
                    <Tab label="Live" component={Link} to="/live" />
                </Tabs>
            </div>

            <h2>YouTube Live</h2>
            <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden', maxWidth: '100%' }}>
                <iframe
                    width="100%"
                    height="100%"
                    src={liveStream}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                ></iframe>
            </div>
        </Container>
    );
};

export default Live;

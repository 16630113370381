import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import NotFound from '../components/NotFound'
import ScoreBoard from '../components/ScoreBoard'
import StepperContainer from '../components/StepperContainer'
import AddTeams from '../components/Teams'
import Matches from '../components/Matches'
import Awards from '../components/Awards'
import Live from '../components/Live'

const Main = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path='/awards' component={Awards} />
        <Route exact path='/matches' component={Matches} />
        <Route exact path='/' component={Matches} />
        <Route exact path='/teams' component={AddTeams} />
        <Route exact path='/start-match' component={StepperContainer} />
        <Route exact path='/score' component={ScoreBoard} />
        <Route exact path='/live' component={Live} />
        <Route path='*' component={NotFound} />
      </Switch>
    </BrowserRouter>
  )
}

export default Main

export const radioGroupBoxstyle = {
  position: 'absolute',
  top: '61%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 340,
  bgcolor: 'background.paper',
  border: '2px solid #3f51b5',
  boxShadow: 24,
  p: '4px',
}

import React, { Fragment } from 'react'
import Main from './Main'

const Container = () => {
  return (
    <Fragment>
      <Main />
      <div style={{ textAlign: 'center' }}>Powered by <a href="https://zarsco.com" target='_blank'>Zarsco</a></div>
    </Fragment>
  )
}

export default Container
